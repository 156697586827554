<template>
  <div class="login mainWrap">

    <div
      class="login-wrap p-2">
      <div class="register-title-wrap mx-auto mb-1 mt-4">
        <div class="inlineBlock title-before"></div>
        <div class="inlineBlock title-text">メールアドレス・パスワードをまだ登録されていない方</div>
      </div>

      <div class="register-text-wrap mx-auto mt-4">
        下記フォームよりメールアドレスとパスワードを登録してください。
      </div>

      <div class="register-btn-wrap mx-auto mt-4 mb-3">
        <b-button
          class="w-100 boldBtn"
          variant="info"
          @click="goInitMail">マイページを作成する</b-button>
      </div>

    </div>

    <div class="login-wrap p-2 mt-5">
      <div class="title-wrap mx-auto mb-1 mt-4">
        <div class="inlineBlock title-before"></div>
        <div class="inlineBlock title-text">メールアドレス・パスワードを登録済の方</div>
      </div>
      <div class="form-wrap mx-auto mt-5">
        <div>
          <div class="inlineBlock label-wrap">
            <label class="bold" for="input-default">メールアドレス</label>
          </div>
          <b-form-input v-model="id"
            class="inlineBlock input-wrap mb-3 h-50px"
            size="md"
            :autocomplete="'email'"/>
        </div>
        <div class="mt-2">
          <div class="inlineBlock label-wrap">
            <label class="bold" for="input-default">パスワード</label>
          </div>
          <b-form-input type="password"
          class="inlineBlock input-wrap mb-4 h-50px"
          v-model="password"
          maxlength="255"
          :autocomplete="'current-password'"/>
        </div>
      </div>

      <b-alert
        class="mt-3 mx-auto error-wrap"
        :show="errorMsg!==''"
        variant="warning">{{errorMsg}}</b-alert>

      <div class="btn-wrap mx-auto">
        <div class="inlineBlock forget-wrap">
          <p class="mt-4">
            <img class="tri" src="@/assets/svg/tri.svg">
            <b-link
              class="ml-1 forget-text"
              :to="resetPassUrl">パスワードを忘れた方はこちらへ</b-link>
          </p>
        </div>
        <div class="inlineBlock login-btn-wrap">
          <b-button
            class="w-100 boldBtn"
            variant="primary"
            @click="authenticate">認証</b-button>
        </div>
      </div>

      <div class="notice-wrap mx-auto mt-2">
        <p class="mb-1 notice-text">※本画面以降で入力された内容はSSL暗号化により保護されます。</p>
        <p class="mb-1 notice-text">※一定時間内にパスワードの認証に5回失敗した場合、24時間認証・ログインができなくなります。</p>
      </div>

    </div>

  </div>
</template>

<script>
import api from '@/modules/api';

export default {
  name: 'TieTop',
  props: {
    msg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: '',
      password: '',
    };
  },
  computed: {
    errorMsg() {
      return this.msg;
    },
    resetPassUrl() {
      return '/identify/password/recover';
    },
  },
  methods: {
    async check() {
      this.$store.dispatch('page/onWaiting');
      const { appId, token } = this.$route.params;
      const param = { appId, tie_token: token };
      await api.send('/api/unicersity/tie/check', param)
        .catch(async () => {
          await this.alert('無効なURLです。', false);
          this.$store.dispatch('page/offWaiting');
          this.$router.push({ path: '/login' });
          return false;
        });
      this.$store.dispatch('page/offWaiting');
    },
    async authenticate() {
      this.$store.dispatch('page/onWaiting');
      const { appId, token } = this.$route.params;
      const inputs = {
        login_id: this.id,
        password: this.password,
        appId,
        tie_token: token,
      };
      this.msg = '';
      const result = await api.login('/api/unicersity/tie/authenticate', inputs)
        .catch((error) => {
          this.msg = error.response.data.msg;
          return false;
        });
      if (!result) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('認証に成功しました。', false);
      this.$store.dispatch('page/offWaiting');
      this.$router.push({ path: '/login' });
    },
    goInitMail() {
      const { appId, token } = this.$route.params;
      this.$router.push({ path: `/university/tie/init/${appId}/${token}` });
    },
  },
  async created() {
    await this.check();
  },
};
</script>

<style scoped>

  .h-50px {
    height: 50px;
  }

  .login{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .tri {
    margin-bottom: 2px;
  }

  .boldBtn {
    font-weight: bold;
  }

  .login-wrap {
    width: 880px;
    border: solid 1px #CCC;
  }

  .title-wrap {
    width: 500px;
  }
  .register-title-wrap {
    width: 650px;
  }
  .title-text {
    margin-left: 18px;
    font-weight: bold;
    font-size: 24px;
  }
  .title-before {
    position: absolute;
    width: 14px;
    height: 36px;
    background-color: #122889;
  }

  .form-wrap, .error-wrap, .btn-wrap, .notice-wrap {
    width: 650px;
  }
  .label-wrap {
    width: 150px;
  }
  .input-wrap {
    width: 460px;
  }

  .forget-wrap {
    width: 400px;
  }
  .forget-text {
    color: #707070;
  }
  .login-btn-wrap {
    width: 200px;
  }

  .notice-text {
    font-size: 13px;
  }

  .register-text-wrap {
    width: 530px;
  }

  .register-btn-wrap {
    width: 460px;
  }
</style>
